<template>
  <v-container
    class="mt-3"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
        lg="4"
      >
        <material-chart-card
          id="monthlyResultsChart"
          :data="monthlyResultsChart.data"
          :options="monthlyResultsChart.options"
          :loading="loading.lab_results && !monthlyResultsChart.data"
          color="indigo darken-1"
          type="Line"
        >
          <h4 class="text-h6 font-weight-light">
            Monthly Lab Result Volume
          </h4>

          <div class="category d-inline-flex font-weight-light">
            <v-icon
              :color="monthlyChange.val > 0 ? 'green' : 'red'"
              small
            >
              {{ monthlyChange.val > 0 ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
            </v-icon>
            <span
              v-if="monthlyResultsChart.data"
              :class="monthlyChange.val > 0 ? 'green--text' : 'red--text'"
              class="mr-1"
            >
              {{ monthlyChange.val | percentage }}
            </span>
            &nbsp; {{ monthlyChange.text }}
          </div>

          <template #actions>
            <v-icon
              class="mr-2"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="text-caption grey--text font-weight-light">{{ $t('updated') }} {{ $moment(monthlyResultsChart.time).diff() | duration('humanize', true) }}</span>
            <v-spacer />
            <v-btn
              icon
              x-small
              @click="fetchMonthlyResults(true)"
            >
              <v-icon
                small
              >
                mdi-reload
              </v-icon>
            </v-btn>
          </template>
        </material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <material-chart-card
          id="dailyLabResultChart"
          :data="dailyLabResultChart.data"
          :options="dailyLabResultChart.options"
          :responsive-options="dailyLabResultChart.responsiveOptions"
          :loading="loading.lab_results && !dailyLabResultChart.data"
          color="purple darken-4"
          type="Bar"
        >
          <h4 class="text-h6 font-weight-light">
            Lab Results this Week
          </h4>
          <div
            v-if="dailyLabResultChart.data.labels.length"
            class="category d-inline-flex font-weight-light"
          >
            Average of {{ dailyLabResultChart.data.series[0]?.reduce((a,b) => a + b) / dailyLabResultChart.data.series[0]?.length | numeral('0') | separator }} positive results per day
          </div>

          <template #actions>
            <v-icon
              class="mr-2"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="text-caption grey--text font-weight-light">{{ $t('updated') }} {{ $moment(dailyLabResultChart.time).diff() | duration('humanize', true) }}</span>
            <v-spacer />
            <v-btn
              icon
              x-small
              @click="fetchDailyResults(true)"
            >
              <v-icon
                small
              >
                mdi-reload
              </v-icon>
            </v-btn>
          </template>
        </material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <material-chart-card
          id="monthlySourceChart"
          :data="monthlySourceChart.data"
          :options="monthlySourceChart.options"
          color="pink darken-2"
          type="Bar"
        >
          <h3 class="text-h6 font-weight-light">
            Specimen Sources
          </h3>
          <div class="category d-inline-flex font-weight-light">
            <span
              v-if="monthlySourceChart.data.labels.length"
              class="mr-1"
            >
              {{ monthlySourceChart.data.series[0]?.reduce((a,b) => a + b) | separator }}
            </span>
            Positive Results in the Last 30 Days
          </div>

          <template #actions>
            <v-icon
              class="mr-2"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="text-caption grey--text font-weight-light">{{ $t('updated') }} {{ $moment(monthlySourceChart.time).diff() | duration('humanize', true) }}</span>
            <v-spacer />
            <v-btn
              icon
              x-small
              @click="fetchMonthlySources(true)"
            >
              <v-icon
                small
              >
                mdi-reload
              </v-icon>
            </v-btn>
          </template>
        </material-chart-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        :lg="12 / ($auth.check({ financials: 'view' }) ? 4 : 3)"
      >
        <material-stats-card
          color="red"
          icon="fal fa-file-magnifying-glass"
          title="Results Awaiting Interpretation"
          :value="lab_results.total"
          sub-icon="mdi-file-outline"
          :loading="loading.lab_results && !lab_results.total"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        :lg="12 / ($auth.check({ financials: 'view' }) ? 4 : 3)"
      >
        <material-stats-card
          color="info"
          icon="fal fa-robot"
          title="Auto Approval Rate"
          :value="approval_time.auto_approval.bot / (approval_time.auto_approval.human + approval_time.auto_approval.bot) | percentage"
          sub-icon="fal fa-badge-check"
          :sub-text="autoStats"
          :loading="loading.approval_time"
          @refresh="fetchApprovalTime(true)"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        lg="6"
      >
        <material-card
          color="pink darken-4"
          title="Lab Results Requiring Attention"
          icon="fa fa-file"
          :text="dailyLabResultChart.data.series ? dailyLabResultChart.data.series[0][dailyLabResultChart.data.series[0]?.length - 1] + ' Received Today' : null"
        >
          <v-data-table
            :headers="lab_results.headers"
            :items="lab_results.items"
            :server-items-length="lab_results.total"
            :loading="loading.lab_results && !lab_results.items"
            hide-default-footer
            item-key="uuid"
            no-results-text="All caught up"
            @click:row="(item) => { labResultLink(item) }"
          >
            <template #item.client="{ item }">
              <client-icon
                :key="item.client.uuid"
                :name="item.client.name"
                :icon="item.client.icon"
              />
            </template>
            <template #item.created_at="{ item }">
              {{ item.created_at | moment('L') }}
            </template>
            <template #item.patient="{ item }">
              <span data-private>{{ item.patient.first_name }} {{ item.patient.last_name }}</span>
            </template>
            <template #item.provider="{ item }">
              <template v-if="item.provider">
                {{ item.provider.first_name.charAt(0) }}. {{ item.provider.last_name }}
                <v-icon
                  v-if="item.created_at > item.provider.advanced_asp"
                  key="advanced_asp_icon"
                  color="cyan"
                  size="14"
                  title="Advanced ASP Provider"
                >
                  fas fa-star
                </v-icon>
              </template>
              <v-icon
                v-else
                color="grey lighten-1"
              >
                fal fa-horizontal-rule
              </v-icon>
            </template>
            <template #item.sample_type="{ item }">
              <source-icon
                v-if="item.sample_type"
                :name="item.sample_type"
                :source="item.sample_source"
              />
            </template>
            <template
              #item.status="{ item }"
            >
              <status-chip
                :key="item.status"
                :status="item.status"
              />
            </template>
          </v-data-table>
        </material-card>
      </v-col>

      <v-col
        cols="12"
        lg="6"
      >
        <material-card
          class="card-tabs"
          color="green"
          title="Activity by Client"
          :text="$moment().format('MMMM YYYY')"
        >
          <v-data-table
            :headers="activity.headers"
            :items="revenue.activity"
            :loading="loading.revenue && !revenue.activity"
            sort-by="lab_results_count"
            sort-desc
            item-key="uuid"
            items-per-page="5"
          >
            <template #item.name="{ item }">
              <client-icon
                :key="item.uuid"
                :name="item.name"
                :icon="item.icon"
              />
            </template>
            <template #item.lab_results_positive="{ item }">
              {{ item.lab_results_positive - item.lab_results_covid_positive }}
            </template>
            <template #item.lab_results_negative="{ item }">
              {{ item.lab_results_negative - item.lab_results_covid_negative }}
            </template>
            <template #item.lab_results_covid="{ item }">
              {{ item.lab_results_covid_positive + item.lab_results_covid_negative }}
            </template>
            <template #item.daily_avg="{ item }">
              {{ item.lab_results_count / $moment().format('D') | numeral('0,0') }}
            </template>
          </v-data-table>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    components: {
      SourceIcon: () => import('@/components/source/SourceIcon.vue'),
      StatusChip: () => import('@/components/StatusChip.vue'),
      ClientIcon: () => import('@/components/ClientIcon.vue'),
    },
    metaInfo: {
      title: 'Dashboard',
    },
    data () {
      return {
        loading: {
          lab_results: false,
          activity: false,
          approval_time: false,
          revenue: false,
        },
        monthlyResultsChart: {
          time: null,
          data: {
            labels: [],
            series: [
              [],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 5,
            }),
            stackBars:true,
            showArea: true,
            showPoint: false,
            fullWidth: true,
            // low: 0,
            // high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 20,
              right: 20,
              bottom: 0,
              left: 6,
            },
            axisX: {
              labelOffset: {
                x: -10,
                y: 3,
              },
            },
            axisY: {
              labelOffset: {
                y: 5,
              },
              labelInterpolationFnc: (value) => value >= 1000 ? this.$options.filters.abbreviate(value) : value,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        monthlySourceChart: {
          time: null,
          data: {
            labels: [],
            series: [[]],
          },
          options: {
            // donut: true,
            // cxstretch: true,
            horizontalBars: true,
            seriesBarDistance: 10,
            // stackBars:true,
            // lineSmooth: this.$chartist.Interpolation.cardinal({
            //   tension: 0,
            // }),
            // low: 0,
            // high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 14,
              right: 20,
              bottom: 0,
              left: 40,
            },
            axisX: {
              labelOffset: {
                x: -5,
                y: 3,
              },
              height:80,
              labelInterpolationFnc: (value) => value >= 1000 ? this.$options.filters.abbreviate(value) : value,
            },
          },
        },
        dailyLabResultChart: {
          data: {
            labels: [],
            series: [[]],
          },
          options: {
            stackBars:true,
            chartPadding: {
              top: 20,
              right: 14,
              bottom: 14,
              left: 6,
            },
            axisX: {
              showGrid: false,
              labelOffset: {
                xx: -10,
                y: 3,
              },
            },
            axisY: {
              labelOffset: {
                y: 5,
              },
              labelInterpolationFnc: (value) => value >= 1000 ? this.$options.filters.abbreviate(value) : value,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        lab_results: {
          items: [],
          headers: [
            {
              text: 'Client',
              value: 'client',
              sortable: false,
            },
            {
              text: 'Result ID',
              value: 'external_id',
              sortable: false,
            },
            {
              text: 'Received',
              value: 'created_at',
              sortable: false,
            },
            {
              text: 'Provider',
              value: 'provider',
              sortable: false,
            },
            {
              text: 'Source',
              value: 'sample_type',
              sortable: false,
            },
            // {
            //   text: 'Organisms',
            //   value: 'organisms_count',
            //   align: 'center',
            //   sortable: false
            // },
            // {
            //   text: 'Resistance',
            //   value: 'resistance_genes_count',
            //   align: 'center',
            //   sortable: false
            // },
            {
              text: 'Status',
              value: 'status',
              sortable: false,
            },
          ],
        },
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
        activity: {
          headers: [
            {
              text: 'Client',
              value: 'name',
              sortable: false,
            },
            {
              text: 'Positive',
              value: 'lab_results_positive',
            },
            {
              text: 'Negative',
              value: 'lab_results_negative',
            },
            {
              text: 'COVID Only',
              value: 'lab_results_covid',
              sortable: false,
            },
            {
              text: 'Total',
              value: 'lab_results_count',
            },
            {
              text: 'Daily Avg',
              value: 'daily_avg',
              sortable: false,
            },
          ],
          data: [],
        },
        revenue: {
          revenue: null,
        },
        approval_time: {
          response_time: null,
          auto_approval: {},
        },
      }
    },
    computed: {
      monthlyChange () {
        const monthly = this.monthlyResultsChart.data
        if (monthly.trend) {
          return {
            val: (monthly.trend.last_period - monthly.trend.prev_period) / monthly.trend.prev_period,
            text: 'compared to the previous 30 day period',
          }
        } else {
          return {
            val: (monthly.series[0][monthly.series[0]?.length - 1] - monthly.series[0][monthly.series[0]?.length - 2]) / monthly.series[0][monthly.series[0]?.length - 2],
            text: 'compared to last month',
          }
        }
      },
      autoStats () {
        if (this.approval_time.potential_match) {
          return 'Confident: ' + this.$options.filters.percentage(this.approval_time.potential_match / this.approval_time.total_results)
              + ' | Pending: ' + this.$options.filters.percentage(this.approval_time.pending_approval / this.approval_time.total_results)
        } else {
          return null
        }
      },
    },
    created () {
      this.fetchMonthlyResults()
      this.fetchMonthlySources()
      this.fetchDailyResults()
      this.fetchLabResults()
      this.fetchRevenue()
      this.fetchApprovalTime()
    },
    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
      fetchMonthlyResults ($no_cache = false) {
        this.loading.lab_results = true
        this.axios.get('charts/lab_results', {
          params: {
            start_date: this.$moment().subtract(11, 'M').format('Y-M-01'),
            trend: 30,
            no_cache: $no_cache,
          },
        })
          .then((res) => {
            this.monthlyResultsChart.data = res.data
            this.monthlyResultsChart.time = res.data.time
          })
          .catch(err => {
            this.$toast.error(err)
          })
          .finally(() => { this.loading.lab_results = false })
      },
      fetchDailyResults ($no_cache = false) {
        this.loading.lab_results = true
        this.axios.get('charts/lab_results', { params: { interval: 'daily', start_date: this.$moment().subtract(7, 'd').format(), no_cache: $no_cache } })
          .then((res) => {
            res.data.series.reverse()
            this.dailyLabResultChart.data = res.data
            this.dailyLabResultChart.time = res.data.time
          })
          .catch(err => {
            this.$toast.error(err)
          })
          .finally(() => { this.loading.lab_results = false })
      },
      fetchMonthlySources ($no_cache = false) {
        this.loading.lab_results = true
        this.axios.get('charts/sources', { params: { start_date: this.$moment().subtract(30, 'd').format(), no_cache: $no_cache } })
          .then((res) => {
            this.monthlySourceChart.data = res.data
            this.monthlySourceChart.time = res.data.time
          })
          .catch(err => {
            this.$toast.error(err)
          })
          .finally(() => { this.loading.lab_results = false })
      },
      fetchLabResults () {
        this.loading.lab_results = true
        this.axios.get('lab_results', {
          params:
            {
              count: 5,
              order: 'desc',
              status: [
                'new',
                'data extraction',
                'high confidence',
                'quality assurance',
                'pending approval',
                'do not interpret',
                'unclassified data',
                'needs review',
              ],
            },
        }).then((response) => {
          this.lab_results.items = response.data.data
          this.lab_results.total = response.data.total
          this.loading.lab_results = false
        }).catch(error => {
          this.loading.lab_results = false
          this.$toast.error(error)
        })
      },
      fetchRevenue ($no_cache = false) {
        this.loading.revenue = true
        this.axios.get('charts/revenue', {
          params: {
            start_date: this.$moment().startOf('month').format(),
            end_date: this.$moment().endOf('month').format(),
            no_cache: $no_cache,
          },
          timeout: 0,
        })
          .then((res) => {
            this.revenue = res.data
          })
          .catch(err => {
            this.$toast.error(err)
          })
          .finally(() => { this.loading.revenue = false })
      },
      fetchApprovalTime($no_cache = false) {
        this.loading.approval_time = true
        this.axios.get('charts/response_time', {
          params: {
            start_date: this.$moment().subtract(30, 'd').format(),
            no_cache: $no_cache,
          },
        })
          .then((res) => {
            this.approval_time = res.data
          })
          .catch(err => {
            this.$toast.error(err)
          })
          .finally(() => { this.loading.approval_time = false })
      },
      labResultLink(item) {
        var destination
        switch(item.status) {
          case 'data extraction':
            destination = 'Data Extraction'
            break
          case 'needs review' && item.interpretation:
          case 'pending approval':
            destination = 'Review Interpretation'
            break
          default:
            destination = 'Interpretation'
        }
        this.$router.push({ name: destination, params: { uuid: item.uuid } })
      },
    },
  }
</script>
<style lang="scss">
  #dailyLabResultChart {
    .ct-label.ct-vertical {
      font-size: 8.5pt;
      line-height: 1.1;
    }
    .ct-series-a {
      .ct-bar {
        stroke: rgb(200, 255, 200);
      }
    }
    .ct-series-b {
      .ct-bar {
        stroke: rgb(255, 200, 200);
        // stroke-dasharray: 3px 1px;
      }
    }
  }

  #dailyLabResultChart .ct-bar {
    stroke-width: 6% !important;
  }

  #monthlySourceChart .ct-bar {
    stroke-width: 3.25% !important;
  }
  #monthlyResultsChart {
    .ct-point{
      stroke-width: 7px !important;
    }
    .ct-series-a {
      .ct-line, .ct-point {
      /* Set the colour of this series line */
        stroke: rgb(245, 152, 152);
        /* Control the thikness of your lines */
        stroke-width: 2px;
        /* Create a dashed line with a pattern */
        // stroke-dasharray: 10px 2px;
      }
      .ct-area {
        fill: rgb(245, 152, 152);
      }
    }
    .ct-series-b {
      .ct-line, .ct-point {
      /* Set the colour of this series line */
        stroke: rgb(132, 243, 132);
        /* Control the thikness of your lines */
        stroke-width: 3px;
      }
      .ct-area {
        fill: rgb(132, 243, 132);
      }
    }
  }
</style>
